import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'

// hoc that wraps a component inside another component with lifecycle
// eventsjiii

function Responsive(Component) {
  return class extends React.Component {
    constructor() {
      super()
      this.state = {width: undefined, height: undefined}
      this.resize = this.resize.bind(this)
    }

    componentDidMount() {
      window.addEventListener('resize', this.resize)
      this.resize()
    }

    resize() {
      const node = this.node
      const bounds = node.getBoundingClientRect()
      const width = bounds.width
      const height = bounds.height
      this.setState({width, height})
    }

    componentWillMount() {
      window.removeEventListener('resize', this.resize)
    }

    render() {
      const {width, height} = this.state
      const {props} = this
      return (
        <div
          style={{width: '100%', height: '100%'}}
          ref={node => (this.node = node)}
        >
          <Component width={width} height={height} {...props} />
        </div>
      )
    }
  }
}

const Chart = ({xdim, ydim, margin, xdata, ydata}) => {
  const canvas = useRef(null)

  useEffect(() => {
    const svg = d3.select(canvas.current)
    addAxes(svg)
    addBars(svg)
    addText(svg)
  }, [xdim, ydim, margin, xdata, ydata])

  const addAxes = svg => {
    const xAxis = d3.axisBottom(xscale)

    svg
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(0,${ydim + margin.top})`)
      .attr('color', 'black')
      .selectAll('text')
      .attr('text-anchor', 'start')
      .attr('color', 'black')
      .attr('transform', 'rotate(45)')

    const yAxis = d3.axisLeft(yscale)

    svg
      .append('g')
      .call(yAxis)
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('color', 'black')
  }

  const addBars = svg => {
    const linearScale = d3
      .scaleLinear()
      .domain([0, d3.max(ydata)])
      .range([0, ydim])

    const scaledYData = ydata.map(yval => {
      return linearScale(yval)
    })

    svg
      .selectAll('rect')
      .data(scaledYData)
      .enter()
      .append('rect')
      .attr('width', xscale.bandwidth())
      .attr('height', d => {
        return d
      })
      .attr('x', (d, i) => {
        return xscale(xdata[i])
      })
      .attr('y', d => {
        return margin.top + ydim - d
      })
      .attr('fill', 'dodgerblue')
      .attr('stroke', 'black')
  }

  const addText = svg => {
    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .text('Land resources')
      .attr('x', (margin.left + margin.right + xdim) / 2)
      .attr('y', margin.top / 2)
    svg
      .append('text')
      .text('Kilograms of edible resources ')

      .attr('x', -(margin.top + margin.bottom + ydim) / 2)
      .attr('y', margin.left / 4)
      .attr('transform', `rotate(-90, ${margin.left / 2}, ${margin.top / 2})`)
  }

  const xscale = d3
    .scaleBand()
    .domain(xdata)
    .range([margin.left, xdim + margin.right])
    .padding(0.1)

  const yscale = d3
    .scaleLinear()
    .domain([0, d3.max(ydata)])
    .range([ydim, 0])

  return (
    <div className="canvas">
      <svg
        viewBox={`0 0 ${xdim + margin.left + margin.right} ${
          ydim + margin.top + margin.bottom
        }`}
        preserveAspectRatio="xMinYMin"
        width="100%"
        height="100%"
        style={{backgroundColor: 'beige'}}
        ref={canvas}
      ></svg>
    </div>
  )
}

export default Chart
