import React, { useCallback } from "react";
import Chart from "../components/Chart";
// @ts-ignore
import video from "../assets/videos/vid.mp4";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Extras = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const ydata = [20, 20, 20, 25, 30, 35, 40, 40, 40, 40, 30, 25];

  const [name, setName] = React.useState([
    { name: "potatoes", month: "May", monthnum: "05", operation: "🌱" },
    {
      name: "tomatoes",
      month: "May",
      monthnum: "05",
      operation: "🌱",
    },
    { name: "melons", month: "May", monthnum: "05", operation: "🌱" },
    { name: "cherry", month: "May", monthnum: "05", operation: "🌱" },
    { name: "beans", month: "May", monthnum: "05", operation: "🌱" },
    { name: "aubergine", month: "May", monthnum: "05", operation: "🌱" },
    { name: "cut weedz", month: "June", monthnum: "06", operation: "🛠️" },
    { name: "fichi", month: "June", monthnum: "06", operation: "🛠️" },
    { name: "cherry", month: "July", monthnum: "07", operation: "🍒" },
    { name: "albicocche", month: "July", monthnum: "07", operation: "🍑" },
    { name: "peaches", month: "July", monthnum: "07", operation: "🍑" },

    {
      name: "balle di fieno",
      month: "July",
      monthnum: "07",
      operation: "✋",
    },

    { name: "nocciole", month: "Agust", monthnum: "08", operation: "🐿️" },
    { name: "cavolfiori", month: "September", monthnum: "09", operation: "🥬" },
    { name: "fichi", month: "September", monthnum: "09", operation: "🥬" },
    {
      name: "mele e pere santa maria",
      month: "September",
      monthnum: "09",
      operation: "🍊",
    },
    { name: "pomodori", month: "September", monthnum: "09", operation: "🍊" },
    { name: "noci", month: "September", monthnum: "09", operation: "🐿️" },
    { name: "uva", month: "October", monthnum: "10", operation: "🍇" },
    { name: "olives", month: "October", monthnum: "10", operation: "🌳" },
    { name: "woodz", month: "October", monthnum: "10", operation: "🪓" },
    { name: "woodz", month: "November", monthnum: "11", operation: "🪓" },
    { name: "finocchi", month: "November", monthnum: "11", operation: "🌱" },
    { name: "kaky", month: "November", monthnum: "11", operation: "🍊" },
    { name: "fave", month: "November", monthnum: "11", operation: "🌱" },
    { name: "grano", month: "November", monthnum: "11", operation: "🌱" },
    { name: "cicoria", month: "November", monthnum: "11", operation: "🌱" },
    { name: "mele", month: "November", monthnum: "11", operation: "🍏" },
    { name: "pere", month: "Dicember", monthnum: "12", operation: "🍐" },
    { name: "more woodz", month: "January", monthnum: "1", operation: "🪓" },
    { name: "PIG", month: "January", monthnum: "1", operation: "🐷" },
    { name: "potature", month: "January", monthnum: "1", operation: "🪓" },
    { name: "FAVE", month: "January", monthnum: "1", operation: "🌱" },
    { name: "...", month: "February", monthnum: "4", operation: "❄️" },
    { name: "potatoes", month: "March", monthnum: "03", operation: "🌱" },
    { name: "aglio", month: "March", monthnum: "05", operation: "🌱" },
    { name: "cipolle", month: "March", monthnum: "05", operation: "🌱" },
    { name: "concime grano", month: "Marzo", monthnum: "4", operation: "☢️" },
    { name: "spinaroli", month: "Marzo", monthnum: "4", operation: "🍄" },
    { name: "asparagi", month: "April", monthnum: "4", operation: "🥬" },
    { name: "mais", month: "April", monthnum: "4", operation: "🌱" },
    { name: "meloni", month: "April", monthnum: "4", operation: "🌱" },
    { name: "basilico", month: "April", monthnum: "4", operation: "🌱" },
  ]);

  const renderHeader = useCallback(() => {
    let headerElements = ["type", "Time of Year", "operation", "delete"];
    return headerElements.map((el, index) => {
      return <th key={index}>{el.toUpperCase()}</th>;
    });
  }, []);

  const removeData = useCallback((i) => {
    const del = name.filter((nam) => nam !== name[i]);
    setName(del);
  }, []);

  var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "");

  const currentmonth: any = utc.substring(4, 6);

  const renderBody = () => {
    const Body = name.map(({ name, month, operation, monthnum }, i) => {
      return (
        <>
          <tr
            title="it's going to be some fun"
            className={
              currentmonth - monthnum <= -3
                ? "nearlythere"
                : currentmonth - monthnum <= -6
                  ? "takeiteasy"
                  : currentmonth - monthnum <= 0
                    ? "hard"
                    : ""
            }
            key={i}
          >
            <td>{name}</td>
            <td>{month}</td>
            <td>{operation}</td>
            <td className="opration">
            </td>
          </tr>
        </>
      );
    });
    return <>{Body}</>;
  };

  const [goats, setGoats] = React.useState(17);
  const [chickens, setChickens] = React.useState(22);

  return (
    <React.Fragment>
      <GatsbySeo
        title="Casa Fraine | Todo's"
        description="La lista delle cose da fare"
        canonical="https://www.casafraine.com/todos-permacultura-abruzzo"
        openGraph={{
          url: "https://www.casafraine.com/todos-permacultura-abruzz",
          title: "Todo's",
          description: "La lista delle cose da fare",

          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "Botanic Api",
            },
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 900,
              height: 800,
              alt: "Botanic Api",
            },
            { url: "https://www.casafraine.com/mapitaly.png" },
            { url: "https://www.casafraine.com/mapitaly.png" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "www.casafraine.com/todo",
          cardType: "summary_large_image",
        }}
      />
      <section className="extras page-todos todos">
        <video className="video-player" poster="/agricoltura-familiare.jpg" loop muted autoPlay>
          <source src={video} type="video/mp4" />
          <track
            kind="captions"
            srcLang="en"
            src="pepper hanging left to dry"
          />
        </video>
        <h1 className="sheet__title">What's up with me </h1>
        <table className="table">
          <thead>
            <tr>{renderHeader()}</tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
        <div className="animals">
          <span>
            <h5>Goats we own right now: {goats}</h5>
            <button onClick={() => setGoats(goats + 1)}>+</button>
            <button onClick={() => setGoats(goats - 1)}>-</button>
          </span>
          <span>
            <h5>Chickens we own right now: {chickens}</h5>
            <button onClick={() => setChickens(chickens + 1)}>+</button>
            <button onClick={() => setChickens(chickens - 1)}>-</button>
          </span>
        </div>
        <Chart
          xdim={450}
          ydim={200}
          margin={{ top: 80, bottom: 80, left: 120, right: 120 }}
          xdata={months}
          ydata={ydata}
        />
      </section>
    </React.Fragment>
  );
};

export default React.memo(Extras);
